<template>
  <div>
   <validation-observer ref="simpleRules">
    <b-card no-body class="border mt-1">
      <b-card-body>
        <b-col xl="4">
          <b-form-group label-for="name">
            <validation-provider #default="{ errors }" name="name" rules="required">
            <label>الدور</label>

            <b-form-input id="helperInput" placeholder="اسم الدور" v-model="roleName" />
            <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> 
          </b-form-group>
        </b-col>
        <!-- <label>إضافة دور </label> -->
        <!-- <b-card-title></b-card-title> -->
        <!-- <b-card-sub-title>Permission according to roles</b-card-sub-title> -->
      </b-card-body>
        <div class="_overflow _table_div" >
            <table class="table table-sm table-borderless font-small-3">
              <!-- TABLE TITLE -->
              <tr class="blue">
                <th><label> name</label></th>
                <th><label>Read</label></th>
                <th><label>create</label></th>
                <th><label>Update</label></th>
                <th><label>Delete</label></th>
              </tr>
              <!-- TABLE TITLE -->

              <!-- ITEMS -->
              <tr v-for="(r, i) in permissionsData" :key="i">
                <td>{{r.resource}}</td>
                <td><b-form-checkbox v-model="r.read"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="r.create"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="r.update"></b-form-checkbox></td>
                <td><b-form-checkbox v-model="r.delete"></b-form-checkbox></td>
              </tr>
              <!-- ITEMS -->
              <!-- <div class=""> -->
                  <b-button variant="purple"   @click="UpdateRoles">تعديل الدور</b-button>
              <!-- </div> -->
            </table>
          </div>
       
      <br /><br />
      <!-- <b-col xl="4"></b-col> -->
     
    </b-card>
          </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BTable,BFormInput,BFormGroup,BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
  BCard, BTable,BFormInput,BFormGroup,BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
    BCard, BTable,BFormInput ,BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BCol,ValidationProvider, ValidationObserver 
     ,required, ToastificationContent
  },

  data(){
    return{
  permissionsData : [
      
      

    ],
      roleName:''
    }
  },

  created(){
     this.getRole()
  },

  // setup() {
  //   const

  // },
  methods:{
      UpdateRoles(c){
      //console.log(c)
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules
          .validate()
          .then((success) => {
            if (success) {

      let data={
        role_name:this.roleName,
        resource_permissions:this.permissionsData
      }
      //console.log(data)
        let url=`/api/v1/information_manager/role_management/roles/${this.$route.params.id}`
this.$http.put(url,data).then((res) => {
                //console.log(res)

                 this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم تعديل الدور بنجاح",
            icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });

                }).catch(error => {

          //console.log(error.response.data.message.errors.role_name)
              this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message.errors.role_name}`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );


      })
          }})})},
    getRole(){
      
this.$http.get("/api/v1/information_manager/role_management/roles/"+this.$route.params.id).then((res) => {
                //console.log(res);
                this.roleName=res.data.data.name
let permissions=res.data.data.resources_permissions
permissions.forEach(el=>{
  this.permissionsData.push({resource: el.name,
        read: el.read,
       update: el.update,
        create: el.create,
        delete: el.delete,})
})
 //console.log(this.permissionsData);
    this.permissionsData.forEach(el => {

       if(el.read  ==1){
         el.read=true

}
     if(el.create  ==1){
         el.create=true

}     if(el.update  ==1){
         el.update=true

}     if(el.delete  ==1){
         el.delete=true

}

       if(el.read  ==0){
         el.read=false

}
     if(el.create  ==0){
         el.create=false

}     if(el.update  ==0){
         el.update=false

}     if(el.delete  ==0){
         el.delete=false

}

    });

              });
               //console.log(this.permissionsData);
    }
  }
};
</script>

<style scoped>
table tbody tr td {
color: #00468e !important;
  font-weight: bold !important;
  font-family: "Cairo", sans-serif !important;
}
.blue{
  background-color: #00468e !important;
}
label {
  color: white !important;
  font-weight: bold;
  font-size: 15px;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  margin-top: 100px;
  margin-left: 10px;
 background-color: #00468e !important;
  width: 200px;
}
</style>